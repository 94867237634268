/**
* Generated automatically at built-time (2025-01-15T06:50:00.859Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skeldervik-utekok",templateKey: "sites/99-ff0fa12b-1c3c-4fe8-90b7-d8f54e06b109"};