/**
* Generated automatically at built-time (2025-01-15T06:50:01.822Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skotselguide-utekok",templateKey: "sites/99-1fc8293d-9b4d-4553-aed6-4812ece05f10"};