import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import ColorVariantType from "@prop-types/ColorVariantType";
import { notificationClosed } from "@redux-actions/notification";
import { NotificationBarBS } from "@style-variables";
import { timestampToHuman } from "@utils/date";
import { escapeReact } from "@utils/react";
import { breakString } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";

class NotificationBar extends PureComponent {
  static AUTOCLOSE_TIMER = "%AUTOCLOSE_TIMER%";
  static DATE_RANGE = "%DATE_RANGE_STR%";
  static VALID_TIMER = "%DATE_RANGE_TIMER%";
  static VALID_FROM = "%VALID_FROM%";
  static VALID_TO = "%VALID_TO%";

  static MOUNT_ON_BODY = "body";
  static MOUNT_ON_HEADER = "header";

  static SCROLL_LEFT_RIGHT = "leftToRight";
  static SCROLL_BOTTOM_TOP = "bottomTop";
  static SCROLL_ANIMATION_IN = "scroll-in";
  static SCROLL_ANIMATION_OUT = "scroll-out";

  constructor(props) {
    super(props);

    this.state = {
      repeatCount:
        (NotificationBar.SCROLL_LEFT_RIGHT === this.props.scroll
          ? this.props.repeatCount
          : 1) || 1,
      htmlText: this.getText(),
      mountTimestamp: +new Date(),
      // used by vertical scroll
      text: null,
      className: NotificationBar.SCROLL_ANIMATION_IN
    };

    this.handleClose = this.handleClose.bind(this);
    this.updateTextPlaceholder = this.updateTextPlaceholder.bind(this);

    this.autoCloseTimerInterval = null;
    this.autoCloseTimeout = null;
    this.verticalScrollInterval = null;

    this.refText = React.createRef();
    this.refWrapper = React.createRef();
  }

  componentDidMount() {
    this.mountCloseTimer();

    this.mountCloseTimeout();

    this.mountVerticalScroll();

    this.updateScrollTextRepeatCount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps._closed !== this.props._closed) {
      this.setState({
        mountTimestamp: this.props._closed ? null : +new Date()
      });

      if (!this.props._closed) {
        this.mountCloseTimer();

        this.mountCloseTimeout();
      }
    }
  }

  componentWillUnmount() {
    this.unmountVerticalScroll();

    this.unmountCloseTimer();

    this.unmountCloseTimeout();
  }

  /**
   * @description Calculate/update the number of times the given text should be repeated for smooth horizontal scrolling
   * @memberof NotificationBar
   */
  updateScrollTextRepeatCount() {
    if (
      this.props.repeatCount ||
      this.props.scroll !== NotificationBar.SCROLL_LEFT_RIGHT
    ) {
      return;
    }

    try {
      const wrapper = this.refWrapper.current.offsetWidth;
      const content = Array.from(this.refText.current.childNodes).reduce(
        (carry, el) => Math.max(carry, el.offsetWidth),
        0
      );

      const repeatCount = 2 * Math.ceil(wrapper / content);

      this.setState({ repeatCount });
    } catch (error) {
      console.error(error);
    }
  }

  mountCloseTimer() {
    const placeholders = [
      NotificationBar.AUTOCLOSE_TIMER,
      NotificationBar.DATE_RANGE,
      NotificationBar.VALID_TIMER,
      NotificationBar.VALID_FROM,
      NotificationBar.VALID_TO
    ];

    if (
      this.props.autoClose &&
      placeholders.some(p => -1 !== this.props.text.indexOf(p))
    ) {
      this.autoCloseTimerInterval = setInterval(
        this.updateTextPlaceholder,
        1000
      );
    }
  }

  mountCloseTimeout() {
    if (this.props.autoClose) {
      this.autoCloseTimeout = setTimeout(
        this.handleClose,
        this.props.autoClose * 1000
      );
    }
  }

  mountVerticalScroll() {
    if (NotificationBar.SCROLL_BOTTOM_TOP !== this.props.scroll) {
      return;
    }

    const scrollElement = this.refText.current;

    // wrap the text around crlf/br
    const str = scrollElement.innerText.split(/\n|<\s*br\s*\/?>/);

    const items = breakString(str, scrollElement);

    const maxWordCount = Math.max(...items.map(s => s.split(" ").length));

    // https://en.wikipedia.org/wiki/Reading#cite_note-:6-160
    const readPerformance = 300 / 60;

    let i = 0;

    let active = NotificationBar.SCROLL_ANIMATION_IN;

    this.setState({ text: items[i] });

    // the read wait #seconds
    let wait = 0;

    this.verticalScrollInterval = setInterval(() => {
      if (wait++ < maxWordCount / readPerformance) {
        return;
      }

      let newActive =
        NotificationBar.SCROLL_ANIMATION_IN === active
          ? NotificationBar.SCROLL_ANIMATION_OUT
          : NotificationBar.SCROLL_ANIMATION_IN;

      if (NotificationBar.SCROLL_ANIMATION_IN === newActive) {
        wait = 0;
      }

      active = newActive;

      const newState = { className: newActive };

      if (NotificationBar.SCROLL_ANIMATION_IN === newActive) {
        i++;

        if (i >= items.length) {
          i = 0;
        }

        newState.text = items[i];
      }

      this.setState(newState);
    }, 1000);
  }

  unmountCloseTimer() {
    if (this.autoCloseTimerInterval) {
      this.autoCloseTimerInterval = clearInterval(this.autoCloseTimerInterval);
    }
  }

  unmountCloseTimeout() {
    if (this.autoCloseTimeout) {
      this.autoCloseTimeout = clearTimeout(this.autoCloseTimeout);
    }
  }

  unmountVerticalScroll() {
    if (this.verticalScrollInterval) {
      this.verticalScrollInterval = clearTimeout(this.verticalScrollInterval);
    }
  }

  /**
   * @description Updats the text placeholder
   * @memberof NotificationBar
   */
  updateTextPlaceholder() {
    if (this.props._closed) {
      return this.unmountCloseTimer();
    }

    const eta =
      this.state.mountTimestamp + this.props.autoClose * 1000 - +new Date();

    const newText = this.getText(eta);

    this.setState({ htmlText: newText });
  }

  /**
   * @description Handle the notification bar close click
   * @param {Event} e The triggering event
   * @memberof NotificationBar
   */
  handleClose(e) {
    if (this.props._closed) {
      this.unmountCloseTimeout();
    } else {
      this.props.notificationClosed(this.props.id, true);

      if ("function" === typeof this.props.onClose) {
        this.props.onClose(e, this.props.id);
      }
    }
  }

  /**
   * @description Get the notification bar text
   * @param {number} [eta=0] When given the ETA the notification bar is automatically closed
   * @returns {String}
   * @memberof NotificationBar
   */
  getText(eta = 0) {
    const UNKNOWN = "UNKNOWN";

    const dateParse = (str, defaultValue) => {
      const result = Date.parse(str);

      return Number.isNaN(result) ? defaultValue : result;
    };

    const getDateValidInterval = (from, to) => {
      const result = Date.parse(to) - (from ? Date.parse(from) : +new Date());

      return Number.isNaN(result) ? null : result;
    };

    const locale = [
      this.props.i18n.siteSettings.languageCode,
      (this.props.i18n.siteSettings.languageCountry || "").toUpperCase()
    ]
      .filter(Boolean)
      .join("-");

    const localeOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    const toLocaleDateString = date => {
      if (!(date instanceof Date)) {
        return date;
      }

      try {
        return date.toLocaleDateString(locale, localeOptions);
      } catch (error) {
        return date.toUTCString();
      }
    };

    const dateRangeInterval = getDateValidInterval(
      this.props.validFrom,
      this.props.validTo
    );
    const dateValidInterval = getDateValidInterval(
      new Date().toISOString(),
      this.props.validTo
    );

    const placeholders = {
      [NotificationBar.AUTOCLOSE_TIMER]: timestampToHuman(eta),
      [NotificationBar.DATE_RANGE]:
        timestampToHuman(dateRangeInterval) || UNKNOWN,
      [NotificationBar.VALID_TIMER]:
        timestampToHuman(dateValidInterval) || UNKNOWN,
      [NotificationBar.VALID_FROM]: toLocaleDateString(
        new Date(dateParse(this.props.validFrom, +new Date()))
      ),
      [NotificationBar.VALID_TO]: toLocaleDateString(
        new Date(dateParse(this.props.validTo, +new Date()))
      )
    };

    return Object.keys(placeholders).reduce(
      (carry, key) => carry.replace(new RegExp(key, "g"), placeholders[key]),
      this.props.text
    );
  }

  /**
   * @description Get the notification item scroll direction
   * @returns {String}
   * @memberof NotificationBar
   */
  getScrollItemClass() {
    switch (this.props.scroll) {
      case NotificationBar.SCROLL_LEFT_RIGHT:
        return "scroll";
      case NotificationBar.SCROLL_BOTTOM_TOP:
        return "scroll-vertical";
      default:
        return null;
    }
  }
  /**
   * @description Render the notification bar text
   * @returns {JSX}
   * @memberof NotificationBar
   */
  renderText() {
    return (
      <span
        ref={this.refText}
        className={[
          "w-100 d-inline-block",
          NotificationBarBS + "-item-text",
          this.getScrollItemClass(),
          this.props.canClose ? "pr-4" : null,
          this.props.centered ? "text-center" : null
        ]
          .filter(Boolean)
          .join(" ")}
        style={{
          "--speed-factor": this.props.speedFactor
        }}
      >
        {escapeReact(
          Array(this.state.repeatCount)
            .fill(this.state.text || this.state.htmlText)
            .join(" "),
          this.props.pathfinder
        )}
      </span>
    );
  }

  /**
   * @description Renders the notification bar close button
   * @returns {JSX}
   * @memberof NotificationBar
   */
  renderCloseBtn() {
    return this.props.canClose ? (
      <button
        className={
          "close text-muted float-right position-absolute mr-2 " +
          NotificationBarBS +
          "-item-close"
        }
        style={{ right: "0.5rem" }}
        aria-label={this.props.btnCloseTitle}
        title={this.props.btnCloseTitle}
        onClick={this.handleClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    ) : null;
  }

  render() {
    if (this.props._closed) {
      return null;
    }

    const rowStyle = {};

    if (this.props.bgVariant) {
      rowStyle.backgroundColor = this.props.bgVariant;
    }
    if (this.props.fgVariant) {
      rowStyle.color = this.props.fgVariant;
    }

    const rowClassName = [
      NotificationBarBS + "-item",
      "mx-0 py-1",
      this.props.className,
      this.props.isRelativeBody ? "position-unset" : null
    ]
      .filter(Boolean)
      .join(" ");

    const colClassName = [
      NotificationBarBS + "-item-wrapper",
      NotificationBar.SCROLL_BOTTOM_TOP === this.props.scroll
        ? "scroll-vertical"
        : null,
      this.state.className
    ]
      .filter(Boolean)
      .join(" ");

    console.log(rowStyle);
    return (
      <Row className={rowClassName} data-id={this.props.id} style={rowStyle}>
        <Col ref={this.refWrapper} className={colClassName}>
          {this.renderText()}
          {this.renderCloseBtn()}
        </Col>
      </Row>
    );
  }
}

NotificationBar.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  centered: PropTypes.bool,
  _closed: PropTypes.bool,
  _timestamp: PropTypes.number,
  canClose: PropTypes.bool,
  btnCloseTitle: PropTypes.string,
  autoClose: PropTypes.number,
  expiryClose: PropTypes.number,
  onClose: PropTypes.func,
  mountOn: PropTypes.oneOf([
    NotificationBar.MOUNT_ON_BODY,
    NotificationBar.MOUNT_ON_HEADER
  ]),
  scroll: PropTypes.oneOf([
    NotificationBar.SCROLL_LEFT_RIGHT,
    NotificationBar.SCROLL_BOTTOM_TOP
  ]),
  adminOnly: PropTypes.bool,
  bgVariant: ColorVariantType(),
  fgVariant: ColorVariantType(),
  repeatCount: PropTypes.number,
  speedFactor: PropTypes.number,
  isFixedTop: PropTypes.bool,
  isFixedBottom: PropTypes.bool,
  isRelativeBody: PropTypes.bool
};

NotificationBar.defaultProps = {
  centered: true,
  canClose: true,
  btnCloseTitle: "Close",
  autoClose: 0, // automatically close the notification after `autoClose` seconds
  expiryClose: 0, // close expires after `expiryClose` seconds
  mountOn: NotificationBar.MOUNT_ON_BODY,
  scroll: "",
  bgVariant: "dark",
  fgVariant: "light",
  adminOnly: true
};

// ------------------- REDUX ----------------------
NotificationBar.mapStateToProps = (state, ownProps) => {
  const notification =
    state.notificationBar.items.find(item => item.id === ownProps.id) || {};

  const _timestamp = notification._timestamp || 0;

  const closeExpired = _timestamp + ownProps.expiryClose * 1000 > +new Date();

  const _closed =
    true === notification._closed && (!ownProps.expiryClose || closeExpired);

  return { _closed, _timestamp };
};

NotificationBar.mapDispatchToProps = {
  notificationClosed
};

const NotificationBarComponent = connectHOCs(NotificationBar, {
  withConnect: true,
  withSite: true
});

NotificationBarComponent.MOUNT_ON_BODY = NotificationBar.MOUNT_ON_BODY;
NotificationBarComponent.MOUNT_ON_HEADER = NotificationBar.MOUNT_ON_HEADER;

export default NotificationBarComponent;
